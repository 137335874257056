<template>
    <div class="container">
        <Account v-if="$route.name=='Account'" />
        <Validation v-if="$route.name=='Validation'" />
    </div>
</template>

<script>
import Account from './Account.vue'
import Validation from './Validation.vue'
    export default {
        name:'KYC',
        components:{
            Account,
            Validation
        },
    }
</script>

<style lang="scss" scoped>
.container{
    width: 100%;
}
</style>