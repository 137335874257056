<template>
    <div class="account-container">



    <div class="title">
            <h2> احراز هویت </h2>
            <p> برای انجام معامله و استفاده از امکانات کندل باید فرایند احراز هویت را تکمیل نمایید . ثبت اطلاعات و احراز هویت شما نهایتا تا ۲ روز کاری انجام میشود. شما میتوانید از وضعیت درخواست خود در قسمت اعلانات مطلع شوید  </p>
    </div>



    <form @submit.prevent="submit" class="content-container">
        <PersonalInfo :data.sync="data" />
        <img class="dots" style="width:90%" src="../../../assets/bubble/dashLines.png" alt="">
        <BankInfo :key="data.bankAccounts.length" :data.sync="data" />
        <img v-if="!state.userInfo.authorized" class="dots" style="width:90%" src="../../../assets/bubble/dashLines.png" alt="">
        <UploadPic v-if="!state.userInfo.authorized" :data.sync="data" />
        <div v-if="!state.userInfo.authorized" class="submit-row">
            <div class="notes">
                <div class="line-row">
                    <span class="greendot"></span>
                    <span> حجم عکس کمتر از ۳ مگابایت باشد </span>
                    
                </div>
                <div class="line-row">
                    <span class="greendot"></span>
                    <span> فرمت فایل JPEG یا PNG باشد </span>
                </div>
            </div>
            <button class="submit"> ثبت و ادامه </button>
        </div>
        <img v-if="!state.userInfo.authorized" class="doubledots" style="width:90%" src="../../../assets/bubble/dashdoublelines.svg" alt="">
    </form>


    </div>
</template>

<script>
import PersonalInfo from './PersonalInfo.vue'
import BankInfo from './BankInfo.vue'
import UploadPic from './UploadPic.vue'
    export default {
        components: { PersonalInfo,BankInfo,UploadPic },
        name:'Validation',
        data() {
            return {
                data:{
                    authenticationType: '',
                    firstName:'',
                    lastName:'',
                    birthDay:'',
                    nationalCode:'',
                    mobileNumber:'',
                    telephone:'',
                    address:'',
                    postalCode:'',
                    email:this.$cookies.get("username") || '',
                    bankAccounts:[],
                    image:null,
                }
            }
        },
        methods: {
            removeDash(items) {
                for (let item of items) {
                    item.cardNumber = item.cardNumber.replace(/-/gi, '');
                }
            },
            checkMobileChange(){
                let bol
                if(this.data.mobileNumber !== this.state.userInfo.mobileNumber || this.$route.name == 'Validation'){
                    bol=true
                }else{
                    bol=false
                }
                return bol
            },
            async submit() {
                this.state.loading = true;
                this.removeDash(this.data.bankAccounts);
                let form_data = new FormData();

                for (let key in this.data) {
                    if (key === 'image') {
                        if (typeof this.data.image !== 'string') {
                            form_data.append("file", this.data.image);
                        }
                    } else {
                        form_data.append(key, this.data[key]);
                    }
                }

                form_data.append("bankAccountsString", JSON.stringify(this.data.bankAccounts));

                    try {
                        let vm = this
                        const config = {
                            onUploadProgress: function (progressEvent) {
                                vm.state.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            }
                        }
                            const res = await this.$axios.post("/users/account", form_data, config)

                        this.state.userInfo.authenticationType = res.baseDTO.authenticationType
                        this.state.userInfo.email = res.baseDTO.email
                        this.state.userInfo.authorized = res.baseDTO.authenticationType === "AUTHORIZED"
                        this.state.userInfo.pending = res.baseDTO.authenticationType === "PENDING"
                        this.$error('', 'اطلاعات با موفقیت ذخیره شد', 'success')
                        this.$getuserInfo()
                        if(this.checkMobileChange()){
                            this.$router.push({name:'FinoTech'});
                        }
                    } catch (e) {
                        if (e.response && e.response.data.message.includes("ShebaCode or CardNumber Is For Another User")) {
                            this.$error('', ' شماره شبا یا کارت قبلا وارد شده است.')
                        }
                    }
            },
        },
        mounted() {
            this.data=this.state.userInfo
        },

    }
</script>

<style lang="scss" scoped>
.submit{
    max-width: 205px;
    width: 100%;
}
.submit-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .notes{
        display: flex;
        flex-direction: column;
        gap: 16px;
        .line-row{
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            .greendot{
                width: 12px;
                height: 12px;
                background: var(--green);
                border-radius: 50%;
            }
            span{
                font-weight: 500;
                font-size: clamp(12px,1vw,10px);
                color: var(--primary);
            }
        }
    }
}


.dots{
    transform: translateX(-40px);
}
.doubledots{
    transform: translate(-50px,-30px);
}



.content-container{
    display: flex;
    flex-direction: column;
    row-gap: 46px;
}




.title{
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    justify-content: space-between;
    width: 100%;
    h2{
        color: var(--black);
        font-weight: 700;
        font-size: 2.1em;
    }
    p{
        color: var(--black);
        font-weight: 400;
        font-size: 1em;
        word-spacing: 2px;
    }
    .text-container{
        display: flex;
        flex-direction: row;
        column-gap: 14px;
        span{
            color: var(--black);
            font-weight: 500;
            font-size: 1em;
        }
    }
}
.account-container{
    display: flex;
    flex-direction: column;
    row-gap: 150px;
    width: 100%;
    &::before{
        content: ' ';
        max-width: 1000px;
        width: 100%;
        height: 100%;
        background: url('../../../assets/bubble/elipse.png');
        background-repeat:no-repeat ;
        background-position: top;
        position: absolute;
        top: -250px;
        right: -400px;
        background-size: cover;
        z-index: -1;
    }
}
</style>