<template>
    <form @input="dash" class="personal-container">
        <h2> اطلاعات بانکی </h2>
        <div v-for="(card,index) in state.userInfo.bankAccounts" :key="index" class="infos">

            <div class="label-container">
                <div  class="input">
                    <label for="input">
                        <span class="span-active"> نام بانک </span>
                        <input disabled v-model="card.bankName" type="text">
                    </label>
                </div>
            </div>


             <div class="label-container">
                <div class="input">
                    <label for="input">
                        <span class="span-active"> شماره کارت </span>
                        <input disabled  v-model="card.cardNumber" type="text">
                    </label>
                </div>
            </div>



            <div class="label-container">
                <div class="input">
                    <label for="input">
                        <span class="span-active"> شماره شبا </span>
                        <input disabled  v-model="card.shebaCode" type="text">
                    </label>
                </div>
            </div>

            <img @click="deleteCart(index)" class="addbtn" src="../../../assets/Icons/remove.svg" alt="">

        </div>
        <div class="infos">

            <div class="label-container">
                <div :class="fBankName? 'div-active':''" class="input">
                    <label for="input">
                        <span class="span-active"> نام بانک </span>
                        <input disabled @focus="fBankName=true" v-model="bankName" type="text">
                    </label>
                </div>
            </div>


             <div class="label-container">
                <div :class="cardNumberWarn? 'div-error' : (fCardNumber? 'div-active':'')" class="input">
                    <label for="input">
                        <span :class="cardNumberWarn? 'span-error' : (fCardNumber || cardNumber? 'span-active':'')"> شماره کارت </span>
                        <input @input="cardNumber=$A2N(cardNumber)" @focus="fCardNumber=true" v-model="cardNumber" type="text">
                    </label>
                </div>
                <span v-if="cardNumberWarn" class="warn"> {{cardNumberWarn}} </span>
            </div>



            <div class="label-container">
                <div :class="shebaCodeWarn? 'div-error' : (fShebaCode? 'div-active':'')" class="input">
                    <label for="input">
                        <span :class="shebaCodeWarn? 'span-error' : (fShebaCode || shebaCode? 'span-active':'')"> شماره شبا </span>
                        <input maxlength="24" @input="shebaCode=$A2N(shebaCode)" @focus="fShebaCode=true" v-model="shebaCode" type="text">
                    </label>
                </div>
                <span v-if="shebaCodeWarn" class="warn"> {{shebaCodeWarn}} </span>
            </div>

            <img @click="addCard" class="addbtn" src="../../../assets/Icons/add.svg" alt="">

        </div>
    </form>
</template>

<script>
    const banks = {
            '603799': 'بانک ملی ایران',
            '589210': 'بانک سپه',
            '627648': 'بانک توسعه صادرات',
            '207177': 'بانک توسعه صادرات',
            '627353': 'بانک تجارت',
            '585983': 'بانک تجارت',
            '627961': 'بانک صنعت و معدن',
            '603770': 'بانک کشاورزی',
            '639217': 'بانک کشاورزی',
            '628023': 'بانک مسکن',
            '627760': 'پست بانک ایران',
            '502908': 'بانک توسعه تعاون',
            '627412': 'بانک اقتصاد نوین',
            '622106': 'بانک پارسیان',
            '639194': 'بانک پارسیان',
            '627884': 'بانک پارسیان',
            '502229': 'بانک پاسارگاد',
            '639347': 'بانک پاسارگاد',
            '639599': 'بانک قوامین',
            '504706': 'بانک شهر',
            '502806': 'بانک شهر',
            '603769': 'بانک صادرات',
            '610433': 'بانک ملت',
            '991975': 'بانک ملت',
            '589463': 'بانک رفاه',
            '502938': 'بانک دی',
            '639607': 'بانک سرمایه',
            '627381': 'بانک انصار',
            '505785': 'بانک ایران زمین',
            '636214': 'بانک آینده',
            '636949': 'بانک حکمت ایرانیان',
            '621986': 'بانک سامان',
            '639346': 'بانک سینا',
            '606373': 'بانک قرض الحسنه مهر',
            '627488': 'بانک کارآفرین',
            '502910': 'بانک کارآفرین',
            '505416': 'بانک گردشگری',
            '639370': 'بانک مهر اقتصاد',
            '628157': 'موسسه اعتباری توسعه',
            '505801': 'موسسه اعتباری کوثر',
        }
    export default {
        name:'PersonalInfo',
        props:['data'],
        data() {
            return {
                bankName:'',
                cardNumber:'',
                shebaCode:'',

                fBankName:'',
                fCardNumber:'',
                fShebaCode:'',



            }
        },
        computed: {
            cardNumberWarn(){
                let err
                if(this.fCardNumber){
                if(!this.CardNumber){
                    err='شماره کارت را وارد کنید'
                }else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            },
            shebaCodeWarn(){
                let err
                if(this.fShebaCode){
                if(!this.shebaCode){
                    err='شماره شبا را وارد کنید'
                }else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            }
        },
        methods: {
              deleteCart(item){
                this.data.bankAccounts.splice(item,1)
            },
            dash(){
                if(this.cardNumber){
                    this.bankName = banks[this.cardNumber  ? this.cardNumber.replace(/-/ig,'').slice(0, 6) : ''] || 'کارت نامعتبر'
                    this.cardNumber = this.$separator(this.cardNumber)
                    let lngth  = this.cardNumber.replace(/-/ig,'').length
                    if(lngth >= 16){
                            this.cardNumber=this.cardNumber.replace(/-/ig,'').slice(0,16);
                            this.cardNumber = this.$separator(this.cardNumber);
                    }
                }
            },
            addCard(){
                if(this.cardNumber && this.shebaCode && this.bankName !== 'کارت نامعتبر'){
                this.data.bankAccounts = [
                            {
                               shebaCode:this.shebaCode,
                               bankName:this.bankName,
                               cardNumber:this.cardNumber.replace(/-/ig,''),
                            },...this.data.bankAccounts
                ]
                    this.shebaCode='',
                    this.cardNumber=''
            }else{
                this.$error('کارت معتبر وارد کنید','')
            }
            }
        },
        mounted() {
            
        },
        created:function(){
            window,addEventListener("click",event => {
                if(!event.target.closest('.label-container')){
                    this.fBankName=''
                    this.fCardNumber=''
                    this.fShebaCode=''
                    }
            })
        }
    }
</script>

<style lang="scss" scoped>
.addbtn{
    cursor: pointer;
}
.date,.label-container{
    width: 30%;
}
.personal-container{
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    h2{
        color: var(--darker-primary);
        font-weight: 700;
        font-size: 1.3em;
    }
}
.infos{
    display: flex;
    flex-direction: row;
    gap: 32px;
    flex-wrap: wrap;

}
@media only screen and(max-width:1000px){
    .infos{
        flex-direction: column;
        align-items: flex-start;
        .label-container{
            width: 100% !important;
        }
    }
}
</style>