<template>
    <div class="personal-container">
        <h2> اطلاعات فردی </h2>
        <div class="infos">

            <div class="label-container">
                <div :class="firstNameWarn? 'div-error' : (fdata.firstName? 'div-active':'')" class="input">
                    <label for="input">
                        <span :class="firstNameWarn? 'span-error' : (fdata.firstName || data.firstName? 'span-active':'')"> نام </span>
                        <input :disabled="!!data.firstName" @input="data.firstName=$S2Fa(data.firstName)" @focus="fdata.firstName=true" v-model="data.firstName" type="text">
                    </label>
                </div>
                <span v-if="firstNameWarn" class="warn"> {{firstNameWarn}} </span>
            </div>


             <div class="label-container">
                <div :class="lastNameWarn? 'div-error' : (fdata.lastName? 'div-active':'')" class="input">
                    <label for="input">
                        <span :class="lastNameWarn? 'span-error' : (fdata.lastName || data.lastName? 'span-active':'')"> نام خانوادگی </span>
                        <input :disabled="!!data.lastName" @input="data.lastName=$S2Fa(data.lastName)" @focus="fdata.lastName=true" v-model="data.lastName" type="text">
                    </label>
                </div>
                <span v-if="lastNameWarn" class="warn"> {{lastNameWarn}} </span>
            </div>


            <date-picker 
                        placeholder="تاریخ تولد" 
                        v-model="data.birthDay"
                        :format="$dateFormat['en']"
                        :display-format="$dateFormat['faDateTime']"
                        :clearable="true"
                        type="datetime"
                        :min="now"
                        :class="'date-fa'"
                        class="date"
                        :disabled="!!data.birthDay"
        />



            <div class="label-container">
                <div :class="nationalCodeWarn? 'div-error' : (fdata.nationalCode? 'div-active':'')" class="input">
                    <label for="input">
                        <span :class="nationalCodeWarn? 'span-error' : (fdata.nationalCode || data.nationalCode? 'span-active':'')"> کد ملی </span>
                        <input :disabled="!!data.nationalCode" @input="data.nationalCode=$A2N(data.nationalCode)" @focus="fdata.nationalCode=true" v-model="data.nationalCode" type="text">
                    </label>
                </div>
                <span v-if="nationalCodeWarn" class="warn"> {{nationalCodeWarn}} </span>
            </div>



            <div class="label-container">
                <div :class="mobileNumberWarn? 'div-error' : (fdata.mobileNumber? 'div-active':'')" class="input">
                    <label for="input">
                        <span :class="mobileNumberWarn? 'span-error' : (fdata.mobileNumber || data.mobileNumber? 'span-active':'')"> تلفن همراه </span>
                        <input @input="data.mobileNumber=$A2N(data.mobileNumber)" @focus="fdata.mobileNumber=true" v-model="data.mobileNumber" type="text">
                    </label>
                </div>
                <span v-if="mobileNumberWarn" class="warn"> {{mobileNumberWarn}} </span>
            </div>



            <div class="label-container">
                <div :class="telePhoneWarn? 'div-error' : (fdata.telePhone? 'div-active':'')" class="input">
                    <label for="input">
                        <span :class="telePhoneWarn? 'span-error' : (fdata.telePhone || data.telePhone? 'span-active':'')"> تلفن ثابت </span>
                        <input @input="data.telePhone=$A2N(data.telePhone)" @focus="fdata.telePhone=true" v-model="data.telePhone" type="text">
                    </label>
                </div>
                <span v-if="telePhoneWarn" class="warn"> {{telePhoneWarn}} </span>
            </div>



            <div style="width:62%" class="label-container">
                <div :class="addressWarn? 'div-error' : (fdata.address? 'div-active':'')" class="input">
                    <label for="input">
                        <span :class="addressWarn? 'span-error' : (fdata.address || data.address? 'span-active':'')"> آدرس </span>
                        <input @focus="fdata.address=true" v-model="data.address" type="text">
                    </label>
                </div>
                <span v-if="addressWarn" class="warn"> {{addressWarn}} </span>
            </div>



            <div class="label-container">
                <div :class="postalCodeWarn? 'div-error' : (fdata.postalCode? 'div-active':'')" class="input">
                    <label for="input">
                        <span :class="postalCodeWarn? 'span-error' : (fdata.postalCode || data.postalCode? 'span-active':'')"> کد پستی </span>
                        <input @input="data.postalCode=$A2N(data.postalCode)" @focus="fdata.postalCode=true" v-model="data.postalCode" type="text">
                    </label>
                </div>
                <span v-if="postalCodeWarn" class="warn"> {{postalCodeWarn}} </span>
            </div>





        </div>
    </div>
</template>

<script>
import { now } from '../../../Lib/georgianToJalali';
    export default {
        name:'PersonalInfo',
        props:['data'],
        data() {
            return {
                fdata:{
                    firstName:false,
                    lastName:false,
                    nationalCode:false,
                    mobileNumber:false,
                    telePhone:false,
                    address:false,
                    postalCode:false,
                },
            }
        },
        computed: {
            firstNameWarn(){
                let err
                if(this.fdata.firstName){
                if(!this.data.firstName){
                    err='نام را وارد کنید'
                }else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            },
            lastNameWarn(){
                let err
                if(this.fdata.lastName){
                if(!this.data.lastName){
                    err='نام خانوادگی را وارد کنید'
                }else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            },
            nationalCodeWarn(){
                let err
                if(this.fdata.nationalCode){
                if(!this.data.nationalCode){
                    err='کد ملی را وارد کنید'
                }else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            },
            mobileNumberWarn(){
                let err
                if(this.fdata.mobileNumber){
                if(!this.data.mobileNumber){
                    err='شماره موبایل خود را وارد کنید'
                }else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            },
            telePhoneWarn(){
                let err
                if(this.fdata.telePhone){
                if(!this.data.telePhone){
                    err='تلفن ثابت خود را وارد کنید'
                }else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            },
            addressWarn(){
                let err
                if(this.fdata.address){
                if(!this.data.address){
                    err='آدرس خود را وارد کنید'
                }else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            },
            postalCodeWarn(){
                let err
                if(this.fdata.postalCode){
                if(!this.data.postalCode){
                    err='کد پستی را وارد کنید'
                }else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            },
            now
        },
        methods: {
            
        },
        mounted() {
            
        },
        created:function(){
            window,addEventListener("click",event => {
                if(!event.target.closest('.label-container')){
                    this.fdata={
                        firstName:false,
                        lastName:false,
                        nationalCode:false,
                        mobileNumber:false,
                        telePhone:false,
                        address:false,
                        postalCode:false,
                    }
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
.date,.label-container{
    width: 30%;
}
.personal-container{
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    h2{
        color: var(--darker-primary);
        font-weight: 700;
        font-size: 1.3em;
    }
}
.infos{
    display: flex;
    flex-direction: row;
    gap: 32px;
    flex-wrap: wrap;

}
@media only screen and(max-width:1000px){
    .infos{
        flex-direction: column;
    }
    .label-container{
        width: 100% !important;
    }
    .date{
        width: 100% !important;
    }
}
</style>