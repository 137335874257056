<template>
    <div class="account-container">



    <div class="title">
            <div class="text-container">
                <img src="../../../assets/Icons/error.svg" alt="">
                <span class="text"> برای ویرایش اطلاعات شخصی میبایست تمام مراحل احراز هویت را مجددا تکمیل کنید </span>
            </div>
            <div class="btn-container">
                <img src="../../../assets/Icons/edit.svg" alt="">
                <a @click.prevent="$router.push({name:'Validation'})" href="" class="btn"> ویرایش و احراز هویت </a>
            </div>
    </div>


    <div class="personal-info">
        <h2> اطلاعات فردی </h2>
        <div class="inputs-container">


            <div class="label-container">
                <div class="input div-active">
                    <label for="input">
                        <span class='span-active'> نام </span>
                        <input disabled v-model="state.userInfo.firstName" type="text">
                    </label>
                </div>
            </div>


            <div class="label-container">
                <div class="input div-active">
                    <label for="input">
                        <span class='span-active'> نام خانوادگی </span>
                        <input disabled v-model="state.userInfo.lastName" type="text">
                    </label>
                </div>
            </div>


            <div class="label-container">
                <div class="input div-active">
                    <label  class="bday">
                        <span class='span-active'> تاریخ تولد </span>
                        <span class="bday-span"> {{$G2J(state.userInfo.birthDay)}} </span>
                    </label>
                </div>
            </div>


            <div class="label-container">
                <div class="input div-active">
                    <label for="input">
                        <span class='span-active'> ایمیل </span>
                        <input disabled v-model="state.userInfo.email" type="text">
                    </label>
                </div>
            </div>



            <div class="label-container">
                <div class="input div-active">
                    <label for="input">
                        <span class='span-active'> کد ملی </span>
                        <input disabled v-model="state.userInfo.nationalCode" type="text">
                    </label>
                </div>
            </div>



            <div class="label-container">
                <div class="input div-active">
                    <label for="input">
                        <span class='span-active'> تلفن همراه </span>
                        <input disabled v-model="state.userInfo.mobileNumber" type="text">
                    </label>
                </div>
            </div>



            <div class="label-container">
                <div class="input div-active">
                    <label for="input">
                        <span class='span-active'> تلفن ثابت </span>
                        <input disabled v-model="state.userInfo.telephone" type="text">
                    </label>
                </div>
            </div>



            <div class="label-container">
                <div class="input div-active">
                    <label for="input">
                        <span class='span-active'> آدرس </span>
                        <input disabled v-model="state.userInfo.address" type="text">
                    </label>
                </div>
            </div>


            <div class="label-container">
                <div class="input div-active">
                    <label for="input">
                        <span class='span-active'> کد پستی </span>
                        <input disabled v-model="state.userInfo.email" type="text">
                    </label>
                </div>
            </div>





        </div>
    </div>
    

    <div class="personal-info">
        <h2> اطلاعات بانکی </h2>
        <div v-for="(card,index) in state.userInfo.bankAccounts" :key="index" class="inputs-container">



            <div class="label-container">
                <div class="input div-active">
                    <label for="input">
                        <span class='span-active'> نام بانک </span>
                        <input disabled v-model="card.bankName" type="text">
                    </label>
                </div>
            </div>



            <div class="label-container">
                <div class="input div-active">
                    <label for="input">
                        <span class='span-active'> شماره کارت </span>
                        <input disabled v-model="card.cardNumber" type="text">
                    </label>
                </div>
            </div>


            <div class="label-container">
                <div class="input div-active">
                    <label for="input">
                        <span class='span-active'> شماره شبا </span>
                        <input disabled v-model="card.shebaCode" type="text">
                    </label>
                </div>
            </div>



        </div>

    </div>

    </div>
</template>

<script>
    export default {
        name:'Account',
        
    }
</script>

<style lang="scss" scoped>
.bday{
height: 27px;
}
.bday-span{
    transform: translateY(10px);
    color: var(--black) !important;
}



.personal-info{
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    h2{
        color: var(--darker-primary);
        font-weight: 700;
        font-size: 1.3em;
    }
    .inputs-container{
        display: flex;
        flex-direction: row;
        gap: 32px;
        flex-wrap: wrap;
        .label-container{
            width: 30%;
        }
    }
}




.btn-container{
    display: flex;
    flex-direction: row;
    column-gap: 3px;
    a{
        color: var(--primary) !important;
        font-weight: 500;
        font-size: 1em;
        
    }
}
.title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .text-container{
        width: 50%;
        display: flex;
        flex-direction: row;
        column-gap: 14px;
        span{
            color: var(--black);
            font-weight: 500;
            font-size: 1em;
        }
    }
}
.account-container{
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    width: 100%;
}
@media only screen and(max-width:1200px){
    .title{
        flex-direction: column;
        row-gap: 20px;
        align-items: center;
    }
}
@media only screen and(max-width:850px){
    .label-container{
        width: 45% !important;
    }
    .inputs-container{
        justify-content: space-between;
    }
}
@media only screen and(max-width:500px){
    .text-container{
        width: 100% !important;
        align-items: flex-start;
    }
    .label-container{
        width: 100% !important;
    }
    .inputs-container{
        justify-content: space-between;
    }
}
</style>